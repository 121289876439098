import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { Provider } from "@fluentui/react-teams";
import { themeNames } from "@fluentui/react-teams";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Groupcard from "./Groupcard";
import Abocard from "./Abocard";
import Tab from "./Tab";
import Login from "./Login";


function App() {
  const [appContext, setAppContext] = useState();
  const [appAppearance, setAppAppearance] = useState(
    themeNames.Default
  );

  useEffect(() => {
    microsoftTeams.initialize(() => {
      /**
       * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
       * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
       **/
      microsoftTeams.getContext((context) => {
        setAppContext(context);
        setAppAppearance(initTeamsTheme(context.theme));

        /**
         * Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
         * for the app to call this API before it dismisses the dialog. If the wait times out, you will
         * see an error indicating that the configuration settings could not be saved.
         **/
        microsoftTeams.appInitialization.notifySuccess();
      });

      /**
       * Theme change handler
       * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#registerOnThemeChangeHandler__theme__string_____void_
       **/
      microsoftTeams.registerOnThemeChangeHandler((theme) => {
        setAppAppearance(initTeamsTheme(theme));
      });
    })
  }, []);

  return (

    <Router>

      <Switch>
        <Route exact path="/abocard" component={Abocard} />
        <Route exact path="/enm" component={Tab} />
        <Route exact path="/groupcard" component={Groupcard} />
        <Provider themeName={appAppearance} lang="en-US">
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/termsofuse" component={TermsOfUse} />
          <Route exact path="/login" component={Login} />
        </Provider>
      </Switch>
    </Router>
  );
}

export default App;

// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
function initTeamsTheme(theme) {
  switch (theme) {
    case "dark":
      return themeNames.Dark;
    case "contrast":
      return themeNames.HighContrast;
    default:
      return themeNames.Default;
  }
}
